<script>
	import { onMount } from "svelte";

	import PasswordField from "../../components/PasswordField.svelte";
	import RequestButton from "../../components/RequestButton.svelte";
	import Requests from "../../utils/requests";
	import Toast from "../../components/Toast.svelte";

	const requests = new Requests();

	let toast_bg;
	let toast_msg;
	let show_toast = false;

	let email = "";
	let password = "";
	let is_making_login_api_request = false;
	let next;

	onMount(() => {
		next = window.location.href.split("?next=")[1] || "/";  

		const onKeyDown = (e) => {
			if (e.key === "Enter") make_login_request();
		};
		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		};
	});

	const make_login_request = () => {
		is_making_login_api_request = true;

		setTimeout(async () => {
			const payload = new FormData();
			payload.append("email", email);
			payload.append("password", password);

			try {
				const response = await requests.post(
					"/api/accounts/login/?next=" + next,
					payload
				);
				window.location.href = response.next;
			} catch (xhr) {
				toast_bg = "bg-gradient-danger";
				if (xhr.status == 400 || xhr.status == 401) {
					toast_msg = "Email or password entered is invalid!";
				} else {
					toast_msg = "Server is unavailable. Please try again later";
				}
				show_toast = true;
			}
			is_making_login_api_request = false;
		}, 1500);
	};
</script>

<Toast
	id="qualification-details-toast"
	bind:bg={toast_bg}
	bind:msg={toast_msg}
	bind:show={show_toast}
/>

<div class="card card-plain mt-3">
	<div class="card-header pb-0 text-left bg-transparent px-2">
		<h2 class="font-weight-bolder text-primary text-gradient">Sign In</h2>
		<p class="mb-0">
			If existing applicant, enter your email and password to continue
		</p>
	</div>
	<div class="card-body animate__animated animate__fadeInLeft px-2">
		<form class="login-form">
			<label for="email">Email</label>
			<div class="mb-3">
				<input
					bind:value={email}
					id="email"
					name="email"
					type="email"
					class="form-control"
					placeholder="Email"
					required
				/>
				<div class="invalid-feedback">Please enter an email.</div>
			</div>
			<PasswordField bind:password />
			<div class="d-flex justify-content-between">
				<p class="password-missing text-sm">
					Forgotten password?
					<a
						href="/accounts/request-password-reset/"
						class="text-primary text-gradient font-weight-bold">Reset here</a
					>
				</p>
			</div>
			<div class="text-center">
				<RequestButton
					is_making_api_request={is_making_login_api_request}
					on:makeAPIRequest={make_login_request}
					text="Sign in"
					classes="btn bg-gradient-primary w-100 mt-4 mb-0"
				/>
			</div>
		</form>
	</div>
	<div class="card-footer text-center pt-0 px-lg-2 px-1">
		<p class="mb-4 text-sm mx-auto">
			Don't have an account?
			<a
				href="/accounts/register/"
				class="text-primary text-gradient font-weight-bold">Create an account</a
			>
		</p>
	</div>
</div>
